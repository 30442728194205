<template>
  <v-autocomplete
    v-model="organism"
    :items="$store.state.selects.organisms"
    :loading="loading"
    :search-input.sync="search"
    :label="$tc('organism', 1) | capitalize"
    cache-items
    dense
    multiple
    clearable
    menu-props="offsetY"
    item-text="name"
    item-value="uuid"
    @change="$emit('update:value', organism)"
  >
    <template #prepend-inner>
      <v-icon
        small
        class="mt-1"
        color="grey"
      >
        fal fa-disease
      </v-icon>
    </template>
    <template #selection="{ item }">
      <v-chip
        x-small
        class="ml-0"
        v-text="item.name"
      />
    </template>
  </v-autocomplete>
</template>
<script>
  export default {
    props: {
      value: {
        type: Array,
        default: () => [],
      },
    },
    data () {
      return {
        loading: false,
        search: null,
        organism: this.value,
        organisms: [],
      }
    },
    watch: {
      search () {
        this.fetchOrganisms()
      },
      value () {
        this.organism = this.value
      },
    },
    created () {
      this.fetchOrganisms()
    },
    methods: {
      fetchOrganisms () {
        this.loading = true
        const promise = this.axios.get('admin/organisms', {
          params:
            {
              fields: ['id','name'],
              count: this.search ? 100 : 20,
              search: this.search,
              sort: 'name',
            },
        })
        return promise.then((response) => {
          this.$store.state.selects.organisms = response.data.data
        }).catch(error => {
          this.$toast.error(error.response?.data?.message)
        }).finally(() => {
          this.loading = false
        })
      },
    },
  }
</script>
